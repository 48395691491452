export const useCopyToClipboard = () => {
  const { $notifications } = useNuxtApp()
  function copyToClipboard(input: string) {
    try {
      navigator.clipboard.writeText(input)
      $notifications.success('Copied to clipboard')
      return true
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e: unknown) {
      $notifications.success('An error occured')
      return false
    }
  }
  return { copyToClipboard }
}
